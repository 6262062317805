.main {
    width: 60%;
    min-height: 100px;
    margin: auto;
    padding: 10% 0;
    display: flex;
    margin-top: 1%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 5px 5px 2px #ccc;

}

.counter {
    margin: 0 15px;
    font-size: 42px;
    font-weight: 600;
    text-shadow: 2px 2px 5px;

}

.limit-inputs-holder {
    width: 60%;
    margin: auto;
    padding: 20px 0;
    display: flex;
    background-color: #ccc;
    justify-content: space-evenly;
    margin-top: 5%;
    border-radius: 4px;
}

.maximum {
    color: green;
    font-weight: 700;
}

.maximum > input {
    margin-left: 10px;
    padding: 6px 10px;
    border: none;
    border-radius: 4px;
    outline: none;
}

.minimum {
    color: red;
    font-weight: 700;
}
.minimum > input {
    margin-left: 10px;
    padding: 6px 10px;
    border: none;
    border-radius: 4px;
    outline: none;
}
