body{
  margin: 0;
  box-sizing: border-box;
}

.App {
  
  display: flex;
  flex-direction: column;
  
  
}

