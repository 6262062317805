.header {
    background-color: #eee;
    padding: 10px;
    box-shadow: 0 2px 5px #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;   
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
    text-shadow: 0 2px 5px ;
}


.login {
    background-color: green;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    margin-right: 10px;
    font-family: 'Noto Sans', sans-serif;
}


.logout {
    background-color: red;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    font-family: 'Noto Sans', sans-serif;
}