.footer {
    background-color: #ccc;
    padding: 10px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    min-height: 50px;
    text-align: center;
    font-family: 'Bebas Neue', cursive;
    font-size: 24px;
    padding-top: 30px;
    display: flex;
    justify-content: center;

}